import React, { useEffect, useState } from "react";
import { Button, Stack, Typography } from "@mui/material";
import Sidebar from "../Sidebar";
import { useSelector } from "react-redux";
import MapIcon from "./assests/map_icon.png";
import documentIcon from "./assests/document_icon.png";
import { Link } from "react-router-dom";
import TopCards from "./TopCards";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from 'dayjs';

const MainDashboard = () => {
  const login = useSelector((store) => store.login.refreshToken);
  const months = [
    { value: "January", label: "January", index: 1 },
    { value: "February", label: "February", index: 2 },
    { value: "March", label: "March", index: 3 },
    { value: "April", label: "April", index: 4 },
    { value: "May", label: "May", index: 5 },
    { value: "June", label: "June", index: 6 },
    { value: "July", label: "July", index: 7 },
    { value: "August", label: "August", index: 8 },
    { value: "September", label: "September", index: 9 },
    { value: "October", label: "October", index: 10 },
    { value: "November", label: "November", index: 11 },
    { value: "December", label: "December", index: 12 },
  ];
  const currentMonthIndex = new Date().getMonth() + 1;
  const currentMonth = months.find(month => month.index === currentMonthIndex);
  const [formData, setFormData] = useState({
    month: currentMonth.value,
    month_no: currentMonth.index,
  });
  const [selectedDate, setSelectedDate] = useState(dayjs());

  useEffect(() => {
    const year = selectedDate.year();
    const month = selectedDate.month() + 1; 
    setFormData((prevData) => ({
      ...prevData,
      year: year,
      month_no: month,
    }));
  }, []);
  const handleMonthChange = (date) => {
    setSelectedDate(date);
    const year = date.year();
    const month = date.month() + 1; 
    setFormData((prevData) => ({
          ...prevData,
          year: year,
          month_no: month,
        }));
  };
  const minDate = dayjs('2024-06-01');
  return (
    <Stack
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        backgroundColor: "#E5E5E5",
        height: "100%",
      }}
    >
      <Stack
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100vw",
          maxWidth: "100vw",
          flex: "1 1 auto",
        }}
      >
        <Sidebar />
        <Stack
          sx={{
            width: "100%",
            height: "100%",
            margin: "4px 4px 0px 0px",
            borderRadius: "4px",
            flexDirection: "column",
            gap: "5px",
            padding: "8px 10px",
          }}
        >
          <Stack
            sx={{
              flexDirection: "row",
              gap: "12px",
              justifyContent: "space-between",
            }}
          >
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "12px",
                alignItems: "center",
              }}
            >
              <Typography sx={{ fontWeight: "700", fontSize: "24px" }}>
                Welcome {login?.user_data?.first_name}
              </Typography>
              <Link
                to="/home/adminMapView"
                style={{ textDecoration: "none" }}
              >
                <Button
                  sx={{
                    backgroundColor: "#fff !important",
                    height: "40px !important",
                    borderRadius: "0px !important",
                    fontWeight: "700 !important",
                    fontSize: "14px !important",
                    border: "1px solid #EF6C1A",
                    color: "#EF6C1A !important",
                    boxShadow: "none !important",
                    ":hover": {
                      backgroundColor: "#fff !important",
                      color: "#EF6C1A !important",
                      boxShadow: "none !important",
                    },
                  }}
                  startIcon={<img src={MapIcon} alt="Map Icon" />}
                  variant="contained"
                >
                  Route
                </Button>
              </Link>
              <Link
                to="/home/dashboardMapView"
                style={{ textDecoration: "none" }}
              >
                <Button
                  sx={{
                    backgroundColor: "#fff !important",
                    height: "40px !important",
                    borderRadius: "0px !important",
                    fontWeight: "700 !important",
                    fontSize: "14px !important",
                    border: "1px solid #EF6C1A",
                    color: "#EF6C1A !important",
                    boxShadow: "none !important",
                    ":hover": {
                      backgroundColor: "#fff !important",
                      color: "#EF6C1A !important",
                      boxShadow: "none !important",
                    },
                  }}
                  startIcon={<img src={MapIcon} alt="Map Icon" />}
                  variant="contained"
                >
                  RT Route
                </Button>
              </Link>
              <Link
                to="/home/downloadDetails"
                style={{ textDecoration: "none" }}
              >
                <Button
                  sx={{
                    backgroundColor: "#fff !important",
                    height: "40px !important",
                    borderRadius: "0px !important",
                    fontWeight: "700 !important",
                    fontSize: "14px !important",
                    border: "1px solid #EF6C1A",
                    color: "#EF6C1A !important",
                    boxShadow: "none !important",
                    ":hover": {
                      backgroundColor: "#fff !important",
                      color: "#EF6C1A !important",
                      boxShadow: "none !important",
                    },
                  }}
                  startIcon={<img src={documentIcon} alt="document Icon" />}
                  variant="contained"
                >
                  Download Reports
                </Button>
              </Link>
              {/* <Link
                to="/home/adminMapView"
                style={{ textDecoration: "none" }}
              >
                <Button
                  sx={{
                    backgroundColor: "#fff !important",
                    height: "40px !important",
                    borderRadius: "0px !important",
                    fontWeight: "700 !important",
                    fontSize: "14px !important",
                    border: "1px solid #EF6C1A",
                    color: "#EF6C1A !important",
                    boxShadow: "none !important",
                    ":hover": {
                      backgroundColor: "#fff !important",
                      color: "#EF6C1A !important",
                      boxShadow: "none !important",
                    },
                  }}
                  startIcon={<img src={MapIcon} alt="Map Icon" />}
                  variant="contained"
                >
                  Admin Map View
                </Button>
              </Link> */}
            </Stack>
            {/* <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "1rem",
                flexWrap: "wrap",
              }}
            >
              <TextField
                select
                label="Month"
                name="month"
                size="small"
                value={formData.month}
                onChange={handleChange}
                variant="outlined"
                style={{ minWidth: "140px", marginRight: "10px" }}
              >
                {months.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Stack> */}
            {/* , '& .MuiOutlinedInput-notchedOutline': {backgroundColor: 'white'} */}
            <div style={{backgroundColor:"#FFF"}}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                views={["year", "month"]}
                value={selectedDate}
                slotProps={{ textField: { size: "small" } }}
                sx={{backgroundColor:"#FFF"}}
                onChange={handleMonthChange}
                minDate={minDate}
              />
            </LocalizationProvider>
            </div>
          </Stack>
          <Stack sx={{ mt: "8px" }}>
            <TopCards formData={formData} />

            {/* <Stack sx={{background:"#fff", padding:"10px", borderRadius:"8px"}}>
              <Typography sx={{ fontWeight: "400", fontSize: "20px" }} >Outlet Coverage</Typography>
            </Stack> */}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
export default MainDashboard;
