import useAxiosPrivate from "../useAxiosPrivate";
import { toast } from "react-toastify";

const useJointWorkingDetails = (selectedData, zoneData, setIsDownloading) => {
  console.log(selectedData,"getJointWorkingDetails")

 
  const Axios = useAxiosPrivate();

  const getJointWorkingDetails = async () => {
    let param = {
      // zone_id: zoneData.zone_id,
      start: selectedData?.from,
      end: selectedData?.to,
    };
    setIsDownloading(true)
    const response = await Axios.post("joint-working-details-download", param);
   
    const data = await response.data;
    if (data.code === 200) {
      console.log(data, "getJointWorkingDetails");
      toast.success("Downloaded Successfully");
      setIsDownloading(false);
      return data.body;
    } else {
      setIsDownloading(false);
      toast.error(data?.message);
    }
  };
  return getJointWorkingDetails;
};

export default useJointWorkingDetails;
