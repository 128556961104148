import React from "react";
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { BaseImgUrl } from "../utility/BaseURL";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
const useAddSchemeState = (radioButtonStatus) => {
  const schemeData = useSelector((state) => state.scheme.schemeCurrentData);
  const retailerData = useSelector((state) => state.scheme.schemeRetailerId);
  const navigate = useNavigate();
  const Axios = useAxiosPrivate();

  let endPoint = "";
  if (radioButtonStatus?.state === true) {
    endPoint = "/add_scheme_state_v2";
  } else if (radioButtonStatus?.zone === true) {
    endPoint = "/add_scheme_zone_v2";
  } else if (radioButtonStatus?.boundary === true) {
    endPoint = "/add_scheme_boundary";
  } else {
    endPoint = "/add_scheme_outlet_v2";
  }

  const addSchemeState = async (obj, selectedSkuId, freeSelectedSkuId) => {
    // console.log(schemeData, "form?.data");
    let param = {
      // scheme_type_id: schemeData?.scheme_type === "Discount Scheme" ? 1 : schemeData?.scheme_type === "Quantity Purchase Exclusive" ? 2 : schemeData?.scheme_type === "Quantity Purchase Inclusive" ? 4 : schemeData?.scheme_type==="Text"? 3 : null,
      scheme_type_id: schemeData?.scheme_type === "Discount Scheme" ? 1 :  schemeData?.scheme_type === "Quantity Purchase Inclusive" ? 4 : schemeData?.scheme_type==="Text"? 3 : null,
      sku_id: selectedSkuId,
      scheme_type: schemeData?.sku_type,
      scheme_name: schemeData?.scheme_name,
      start_date: schemeData?.first_date,
      end_date: schemeData?.last_date,
      discount_percentage: schemeData?.percentage,
      qty: schemeData?.scheme_type === "Discount Scheme" ? schemeData?.min_qty : schemeData?.qty,
      qty_type: schemeData?.qty_type,
      free_sku_qty_type: schemeData?.free_sku_qty_type,
      free_sku_qty: schemeData?.free_sku_qty,
      // free_sku_id:schemeData?.free_sku_id,
      free_sku_id_list: freeSelectedSkuId,
      retailer_id: retailerData,
      campaign_id: schemeData?.campaign_id || null,
      sc_id: schemeData?.sc_id
    };

    if(radioButtonStatus?.state===true){
      param.state_id=obj?.state_id
    }else if(radioButtonStatus?.zone===true){
      param.zone_id=obj?.zone_id
    }else{
      param.retailer_id=retailerData
    }
    try{
      const response = await Axios.post(endPoint, param);
      const data = await response.data;
          if (data.code === 200) {
      navigate("/home/Schememgt");
      toast.success("Added SuccessFully");
    }
      console.log(data,"radioButtonStatus")
    }catch(error){
      if(error.response.data.code===400){
        // toast.error(error.response.data?.message);
        Swal.fire({
          title: "Error",
          text: error.response.data?.message ,
          icon: "error",
          showConfirmButton: true,
        });
      }
      console.log(error,"radioButtonStatus")
    }
  
//  if (data.code === 400) {
    //   toast.error(data?.message);
    // } else {
    //   toast.error("Failed to Add");
    // }
  };
  return addSchemeState;
};

export default useAddSchemeState;
