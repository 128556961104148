import React from "react";
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  TextField,
  MenuItem,
  Box,
} from "@material-ui/core";
import TablePagination from '@mui/material/TablePagination';
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import useEditTarget from "../../../hooks/useEditTarget";
import { Typography, Stack } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import coinImage from "../TargetAssigned/coin.png";
import dayjs from 'dayjs';
import useCoinDetails from "../../../hooks/useCoinDetails";
import useHolidaysList from "../../../hooks/useHolidaysList";
import ExcelExport from "./ExcelExport";
import { Navigate, useNavigate } from "react-router";
import useSRCoinDetails from "../../../hooks/CoinManagement/useSRCoinDetails";
const CoinManagementTable = () => {

  const headerName = [
    { index: 0, name: "SR" },
    { index: 1, name: "SR Name" },
    { index: 2, name: "Fixed Salary" },
    { index: 3, name: "Call Stamp Coins" },
    { index: 4, name: "Time Stamp Coins" },
    { index: 5, name: "Sales Stamp Coins" },
    { index: 6, name: "Variable Salary" },
    { index: 7, name: "Kms Travelled" },
    { index: 8, name: "Call Incentive" },
    { index: 9, name: "Sales Incentive" },
    { index: 10, name: "Incentive Sum" },
    { index: 11, name: "Coins Earned" },
    { index: 12, name: "Action" },
  ];
  const navigate=useNavigate()
  const [editModeRowId, setEditModeRowId] = useState(null);
  const [editedValues, setEditedValues] = useState({});
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const getTableData = useCoinDetails();
  const handleDateChange = async(date) => {
    setSelectedDate(date);
    const year = date.year();
    const month = date.month() + 1; 
    const param = {
      zone_id:currentzone?.zone_id,
      year:year,
      month:month
    }
    await getTableData(param);
  };
  const currentzone = useSelector((state) => state.app.curretDropdownData);
  const tableData = useSelector((state) => state.performance.coinTableDetails);
  const holidays_count = useSelector((state) => state.performance.holidayCount);
  const currentState= useSelector((state)=>state.app.curretDropdownData)
  const getHolidayCount = useHolidaysList();

  const getAllSRCoinDetails= useSRCoinDetails()

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  
  useEffect(() => {
    const year = selectedDate.year();
    const month = selectedDate.month() + 1; 
    const param = {
      zone_id:currentzone?.zone_id,
      year:year,
      month:month
    }
    const param2 = {
      state_id : currentzone?.state_id
    }
    getTableData(param);
    getHolidayCount(param2);
  }, [currentzone]);

  useEffect(() => {
    const year = selectedDate.year();
    const month = selectedDate.month() + 1; 
    const param = {
      zone_id:currentzone?.zone_id,
      year:year,
      month:month,
      state_id: currentState?.state_id
    }
    getAllSRCoinDetails(param)
  }, [currentzone, selectedDate]);

  const [workingDaysInMonth, setWorkingDaysInMonth] = useState(0);
  const [workingDaysPassed, setWorkingDaysPassed] = useState(0);

  useEffect(() => {
    const getWorkingDaysInMonth = (year, month) => {
      let totalWorkingDays = 0;
      const daysInMonth = new Date(year, month + 1, 0).getDate();
      for (let day = 1; day <= daysInMonth; day++) {
        const date = new Date(year, month, day);
        const dayOfWeek = date.getDay();
        if (dayOfWeek !== 0) { // Exclude Sundays (0 represents Sunday)
          totalWorkingDays++;
        }
      }
      return totalWorkingDays;
    };
  
    const getWorkingDaysPassedInMonth = (year, month) => {
      const today = new Date();
      if (today.getFullYear() !== year || today.getMonth() !== month) {
        return 0;
      }
      
      let workingDays = 0;
      for (let day = 1; day <= today.getDate(); day++) {
        const date = new Date(year, month, day);
        const dayOfWeek = date.getDay();
        if (dayOfWeek !== 0) { // Exclude Sundays (0 represents Sunday)
          workingDays++;
        }
      }
      return workingDays;
    };
  
    const year = selectedDate.year();
    const month = selectedDate.month();
    // console.log(month, year, "month");
  
    setWorkingDaysInMonth(getWorkingDaysInMonth(year, month));
    setWorkingDaysPassed(getWorkingDaysPassedInMonth(year, month));
  }, [selectedDate, holidays_count]);
  

  const editUserTarget = useEditTarget();
  const handleSave = async () => {
    await editUserTarget(editedValues);
    // await getTableData(formData);
    setEditModeRowId(null);
    setEditedValues({});
  };

  const handleCancel = () => {
    // Cancel edit mode
    setEditModeRowId(null);
    setEditedValues({});
  };

  const handleInputChange = (e, field) => {
    const { value } = e.target;
    setEditedValues((prevValues) => ({
      ...prevValues,
      [field]: value,
    }));
  };

  // const data = [
  //   {
  //     user_id: 185,
  //     sr_name: "Amit Kumar",
  //     fixed_salary: 1255,
  //     call_stamp_coins: 870,
  //     call_achieved: 500,
  //     total_call_target: 1200,
  //     time_stamp_coins: 633,
  //     time_achieved: 65,
  //     total_time_target: 125,
  //     sales_stamp_coins: 1800,
  //     sales_achieved: 80000,
  //     total_sales_target: 180000,
  //     variable_salary_coins: 3303,
  //     petrol_coins: 150,
  //     petrol_kms: 50,
  //     call_incentive_calls_coins: 0,
  //     call_incentive_coins: "0 Call",
  //     sales_incentive_sales_coins: 0,
  //     sales_incentive_coins: "0 Rs",
  //     incentives_sum: 0,
  //     coins_earned: 4708,
  //   },
  //   {
  //     user_id: 166,
  //     sr_name: "Memon",
  //     fixed_salary: 1489,
  //     call_stamp_coins: 766,
  //     call_achieved: 500,
  //     total_call_target: 1200,
  //     time_stamp_coins: 545,
  //     time_achieved: 65,
  //     total_time_target: 125,
  //     sales_stamp_coins: 1788,
  //     sales_achieved: 80000,
  //     total_sales_target: 180000,
  //     variable_salary_coins: 3099,
  //     petrol_coins: 150,
  //     petrol_kms: 50,
  //     call_incentive_calls_coins: 0,
  //     call_incentive_coins: "0 Call",
  //     sales_incentive_sales_coins: 0,
  //     sales_incentive_coins: "0 Rs",
  //     incentives_sum: 0,
  //     coins_earned: 4738,
  //   },
  //   {
  //     user_id: 170,
  //     sr_name: "Hiren",
  //     fixed_salary: 1145,
  //     call_stamp_coins: 567,
  //     call_achieved: 500,
  //     total_call_target: 1200,
  //     time_stamp_coins: 644,
  //     time_achieved: 65,
  //     total_time_target: 125,
  //     sales_stamp_coins: 1234,
  //     sales_achieved: 80000,
  //     total_sales_target: 180000,
  //     variable_salary_coins: 2445,
  //     petrol_coins: 150,
  //     petrol_kms: 50,
  //     call_incentive_calls_coins: 0,
  //     call_incentive_coins: "0 Call",
  //     sales_incentive_sales_coins: 0,
  //     sales_incentive_coins: "0 Rs",
  //     incentives_sum: 0,
  //     coins_earned: 3740,
  //   },
  //   {
  //     user_id: 171,
  //     sr_name: "Suresh",
  //     fixed_salary: 1782,
  //     call_stamp_coins: 899,
  //     call_achieved: 500,
  //     total_call_target: 1200,
  //     time_stamp_coins: 545,
  //     time_achieved: 65,
  //     total_time_target: 125,
  //     sales_stamp_coins: 1788,
  //     sales_achieved: 80000,
  //     total_sales_target: 180000,
  //     variable_salary_coins: 3232,
  //     petrol_coins: 150,
  //     petrol_kms: 50,
  //     call_incentive_calls_coins: 5,
  //     call_incentive_coins: "0 Call",
  //     sales_incentive_sales_coins: 9,
  //     sales_incentive_coins: "0 Rs",
  //     incentives_sum: 14,
  //     coins_earned: 5178,
  //   },
  //   {
  //     user_id: 167,
  //     sr_name: "Pankaj",
  //     fixed_salary: 1563,
  //     call_stamp_coins: 456,
  //     call_achieved: 500,
  //     total_call_target: 1200,
  //     time_stamp_coins: 344,
  //     time_achieved: 65,
  //     total_time_target: 125,
  //     sales_stamp_coins: 1656,
  //     sales_achieved: 80000,
  //     total_sales_target: 180000,
  //     variable_salary_coins: 2456,
  //     petrol_coins: 150,
  //     petrol_kms: 50,
  //     call_incentive_calls_coins: 23,
  //     call_incentive_coins: "0 Call",
  //     sales_incentive_sales_coins: 78,
  //     sales_incentive_coins: "0 Rs",
  //     incentives_sum: 101,
  //     coins_earned: 4270,
  //   },
  //   {
  //     user_id: 174,
  //     sr_name: "Vijay",
  //     fixed_salary: 1256,
  //     call_stamp_coins: 766,
  //     call_achieved: 500,
  //     total_call_target: 1200,
  //     time_stamp_coins: 568,
  //     time_achieved: 65,
  //     total_time_target: 125,
  //     sales_stamp_coins: 1234,
  //     sales_achieved: 80000,
  //     total_sales_target: 180000,
  //     variable_salary_coins: 2568,
  //     petrol_coins: 150,
  //     petrol_kms: 50,
  //     call_incentive_calls_coins: 0,
  //     call_incentive_coins: "0 Call",
  //     sales_incentive_sales_coins: 0,
  //     sales_incentive_coins: "0 Rs",
  //     incentives_sum: 0,
  //     coins_earned: 3974,
  //   },
  // ];

  const data= useSelector((state)=>state.sr.srCoinDetails)

  const headerStyle = {
    backgroundColor: '#1976d2',
    color: 'white',
    textAlign: 'center',
    fontSize: '14px',
    padding: '8px'
  };

  const cellStyle = {
    textAlign: 'center',
    padding: '8px',
    fontSize: '14px',
    backgroundColor: '#f0f7ff'
  };

  const handleDetails=(e, user_id)=>{
    navigate("/home/coinMgtDetails", { state: { selectedDate, userId: user_id} });
  }
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              views={["year", "month"]}
              value={selectedDate}
              slotProps={{ textField: { size: "small" } }}
              onChange={handleDateChange}
            />
          </LocalizationProvider>
          <Stack sx={{ display: "flex", flexDirection: "row", gap: "10px" }}>
            <p>
              MTD Working days :{" "}
              <span style={{ color: "#0C8709", fontWeight: "700" }}>
                {workingDaysPassed}
              </span>
            </p>
            <p>
              Total Working days :{" "}
              <span style={{ color: "#000", fontWeight: "700" }}>
                {workingDaysInMonth - holidays_count?.total_holiday_days}
              </span>
            </p>
          </Stack>
        </Stack>
        <ExcelExport />
      </Box>

      <TableContainer
        style={{ maxHeight: 700, minWidth: "100%", fontSize: "14px" }}
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead style={{ backgroundColor: "#F8F8F8", fontSize: "14px" }}>
            <TableRow>
              {headerName?.map((header) => (
                <TableCell
                  key={header?.index}
                  // style={{ textAlign: "center", width: "200px" }}
                  sx={headerStyle}
                >
                  {header?.name}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.sr_coin_details?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((row, index) => (
              <TableRow key={row.sr_name}>
                <TableCell sx={cellStyle}>{page * rowsPerPage + index + 1}</TableCell>
                <TableCell sx={cellStyle}>
                  <Stack sx={{ width: "6rem" }}>
                    {row.sr_name?.length > 8
                      ? `${row.sr_name.slice(0, 8)}...`
                      : row.sr_name}
                  </Stack>
                </TableCell>

                <TableCell sx={cellStyle}>
                  <Stack
                    alignItems="center"
                    // justifyContent="center"
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "0.2rem",
                    }}
                  >
                    {row.fixed_salary??0}
                    <img src={coinImage} style={{ width: "1rem" }} />
                    
                  </Stack>
                  <Typography
                      variant="caption"
                      color="textSecondary"
                      sx={{ width: "100px" }}
                    >
                      {row.fixed_salary_count ?? 0} days
                    </Typography>
                  {/* <Typography
                    variant="caption"
                    color="textSecondary"
                    sx={{ visibility: "hidden" }}
                  >
                   {row.fixed_salary??0}
                  </Typography> */}
                </TableCell>
                <TableCell sx={{ cellStyle }}>
                  <Stack alignItems="center">
                    <Stack
                      alignItems="center"
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "0.2rem",
                      }}
                    >
                      {row.call_stamp_coins??0}
                      <img src={coinImage} style={{ width: "1rem" }} />
                    </Stack>
                    <Typography
                      variant="caption"
                      color="textSecondary"
                      sx={{ width: "100px" }}
                    >
                      {row.call_achieved??0} / {row.total_call_target??0}  Calls
                    </Typography>
                  </Stack>
                </TableCell>
                <TableCell sx={cellStyle}>
                  <Stack alignItems="center">
                    <Stack
                      alignItems="center"
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "0.2rem",
                      }}
                    >
                      {row.time_stamp_coins ??0}
                      <img src={coinImage} style={{ width: "1rem" }} />
                    </Stack>
                    <Typography
                      variant="caption"
                      color="textSecondary"
                      sx={{ width: "100px" }}
                    >
                      {row.time_achieved??0} / {row.total_time_target??0} hrs
                    </Typography>
                  </Stack>
                </TableCell>

                {/* Sales Stats Cell */}
                <TableCell sx={cellStyle}>
                  <Stack alignItems="center">
                    <Stack
                      alignItems="center"
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "0.2rem",
                      }}
                    >
                      {row.sales_stamp_coins??0}
                      <img src={coinImage} style={{ width: "1rem" }} />
                    </Stack>
                    <Typography
                      variant="caption"
                      color="textSecondary"
                      sx={{ width: "120px" }}
                    >
                      {row.sales_achieved / 1000??0}k /{" "}
                      {row.total_sales_target / 1000??0}k Rs
                    </Typography>
                  </Stack>
                </TableCell>
                <TableCell sx={cellStyle}>
                  <Stack
                    alignItems="center"
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "0.2rem",
                    }}
                  >
                    {row.variable_salary_coins ??0}
                    <img src={coinImage} style={{ width: "1rem" }} />
                  </Stack>

                  <Typography
                    variant="caption"
                    color="textSecondary"
                    sx={{ visibility: "hidden" }}
                  >
                    {row.fixed_salary??0}
                  </Typography>
                </TableCell>
                <TableCell sx={cellStyle}>
                  <Stack alignItems="center">
                    <Stack
                      alignItems="center"
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "0.2rem",
                      }}
                    >
                      {row.petrol_coins??0}
                      <img src={coinImage} style={{ width: "1rem" }} />
                    </Stack>
                    <Typography variant="caption" color="textSecondary">
                      {row.petrol_kms??0} kms
                    </Typography>
                  </Stack>
                </TableCell>
                <TableCell sx={cellStyle}>
                  <Stack alignItems="center">
                    <Stack
                      alignItems="center"
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "0.2rem",
                      }}
                    >
                      {row.call_incentive_calls_coins??0}
                      <img src={coinImage} style={{ width: "1rem" }} />
                    </Stack>
                    <Typography variant="caption" color="textSecondary">
                      {row.call_incentive_calls ?? 0} call
                    </Typography>
                  </Stack>
                </TableCell>
                <TableCell sx={cellStyle}>
                  <Stack alignItems="center">
                    <Stack
                      alignItems="center"
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "0.2rem",
                      }}
                    >
                      {row.sales_incentive_sales_coins??0}
                      <img src={coinImage} style={{ width: "1rem" }} />
                    </Stack>
                    <Typography variant="caption" color="textSecondary" >
                      {row.sales_incentive_cost ?? 0} Rs
                    </Typography>
                  </Stack>
                </TableCell>
                <TableCell sx={cellStyle}>
                  <Stack
                    alignItems="center"
                    // justifyContent="center"
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "0.2rem",
                    }}
                  >
                    {row.incentives_sum??0}
                    <img src={coinImage} style={{ width: "1rem" }} />
                  </Stack>
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    sx={{ visibility: "hidden" }}
                  >
                    {row.fixed_salary??0}
                  </Typography>
                </TableCell>
                <TableCell sx={cellStyle}>
                  <Stack
                    alignItems="center"
                    // justifyContent="center"
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "0.2rem",
                    }}
                  >
                    {row.coins_earned??0}
                    <img src={coinImage} style={{ width: "1rem" }} />
                  </Stack>
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    sx={{ visibility: "hidden" }}
                  >
                    {row.fixed_salary??0}
                  </Typography>
                </TableCell>

                <TableCell sx={cellStyle}>
                  <Button
                    style={{ width: "0.8rem" }}
                    onClick={(e) => handleDetails(e, row?.user_id)}
                  >
                    Details
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
      component="div"
      count={data?.sr_coin_details?.length}
      page={page}
      onPageChange={handleChangePage}
      rowsPerPage={rowsPerPage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
    </>
  );
};

export default CoinManagementTable;
