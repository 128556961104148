import React, { useEffect, useState } from "react";
import { Stack, Typography, Button } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";

import useRetailerDownloadList from "../../hooks/useRetailerDownloadList";

import { useSelector } from "react-redux";

import ExcelJS from 'exceljs';

import useSkuDetailsExcelDownload from "../../hooks/useSkuDetailsExcelDownload";
import * as XLSX from "xlsx";
import useSalesDumpDownload from "../../hooks/useSalesDumpDownload";
import useSkuListExcelDownload from "../../hooks/useSkuListExcelDownload";
import DownloadLoader from "./DownloadLoader";
import useSOpunchingDetails from "../../hooks/useSOpunchingDetails";
import useASMpunchingDetails from "../../hooks/useASMpunchingDetails";
import useVisitReport from "../../hooks/DownloadRangeFile/useVisitReport";
import useSRPerformanceMetrix from "../../hooks/DownloadRangeFile/useSRPerformanceMetrix";
import useAttendanceReport from "../../hooks/DownloadRangeFile/useAttendanceReport";
import useJointWorkingDetails from "../../hooks/DownloadRangeFile/useJointWorkingDetails";

const DownloadFile = ({ selectedData }) => {
  const [currentId, setCurrentId] = useState(null);
  const [isDownloading, setIsDownloading] = useState(false);
  const userData = useSelector(
    (state) => state?.login?.refreshToken?.user_data
  );
  const downloadName = [
    {
      id: 1,
      name: "Productwise Sales Format",
    },
    // {
    //   id: 2,
    //   name: "Product Catalogue",
    // },
    {
      id: 3,
      name: "Retailers Report",
    },
    {
      id: 4,
      name: "Sales Report",
    },
    // {
    //   id: 5,
    //   name: "SO Report",
    // },
    // {
    //   id: 6,
    //   name: "ASM Report",
    // },
    {
      id: 7,
      name: "Attendance report",
    },
    {
      id: 9,
      name: "Attendance report(HR)",
    },
    {
      id: 8,
      name: "SR Performance Metrix",
    },
    {
      id: 10,
      name: "Joint Working Details",
    },
    // {
    //   id: 9,
    //   name: "Visit report",
    // },
  ];



  
  // const generateExcel = (id, apiData) => {
  //   setCurrentId(id)
  //   const rows = [];
  //   const uniqueDates = new Set();
  
  //   // Step 1: Transform data
  //   apiData?.forEach((entry) => {
  //     const { first_name, partner_name, details, user_role, partner_role } = entry;
  
  //     details?.forEach(({ date, total_calls, productive_calls, final_total_cost }) => {
  //       uniqueDates.add(date);
  
  //       const existingRow = rows.find(
  //         (row) => row.Name === first_name && row.PartnerName === partner_name
  //       );
  
  //       if (existingRow) {
  //         existingRow[`${date}_TC`] = total_calls;
  //         existingRow[`${date}_PC`] = productive_calls;
  //         existingRow[`${date}_Sales`] = final_total_cost;
  //       } else {
  //         const newRow = {
  //           Name: first_name,
  //           Designation: user_role, // Add actual designation if available
  //           PartnerName: partner_name,
  //           PartnerDesignation: partner_role, // Add actual partner designation if available
  //           [`${date}_TC`]: total_calls,
  //           [`${date}_PC`]: productive_calls,
  //           [`${date}_Sales`] : final_total_cost,
  //         };
  //         rows.push(newRow);
  //       }
  //     });
  //   });
  
  //   // Step 2: Create columns dynamically
  //   const sortedDates = Array.from(uniqueDates).sort();
  
  //   // Step 3: Export as Excel
  //   const workbook = XLSX.utils.book_new();
  //   const worksheet = XLSX.utils.json_to_sheet([]);
  
  //   // Add headers with merged cells for dates
  //   const dateHeaders = sortedDates.flatMap((date) => [date, null, null]);
  //   const subHeaders = sortedDates.flatMap(() => ["TC", "PC", "Sales"]);
  
  //   XLSX.utils.sheet_add_aoa(
  //     worksheet,
  //     [
  //       [...Array(4).fill(null), ...dateHeaders], // First row with only date headers
  //       ["Name", "Designation", "PartnerName", "PartnerDesignation", ...subHeaders],
  //     ],
  //     { origin: "A1" }
  //   );
  
  //   // Add rows for data
  //   rows.forEach((row) => {
  //     const rowData = [
  //       row.Name,
  //       row.Designation,
  //       row.PartnerName,
  //       row.PartnerDesignation,
  //       ...sortedDates.flatMap((date) => [
  //         row[`${date}_TC`] || "",
  //         row[`${date}_PC`] || "",
  //         row[`${date}_Sales`] || "",
  //       ]),
  //     ];
  //     XLSX.utils.sheet_add_aoa(worksheet, [rowData], { origin: -1 });
  //   });
  
  //   // Merge cells for each date header
  //   const mergeRanges = [];
  //   sortedDates.forEach((date, index) => {
  //     const startCol = 4 + index * 3; // Offset for initial columns and date group
  //     mergeRanges.push({ s: { r: 0, c: startCol }, e: { r: 0, c: startCol + 2 } });
  
  //     // Center align the merged cells for dates
  //     const dateCellAddress = XLSX.utils.encode_cell({ r: 0, c: startCol });
  //     worksheet[dateCellAddress] = worksheet[dateCellAddress] || {};
  //     worksheet[dateCellAddress].s = {
  //       alignment: { horizontal: "center" },
  //     };
  //   });
  
  //   worksheet['!merges'] = mergeRanges;
  
  //   // Adjust column widths for better readability
  //   worksheet['!cols'] = [
  //     { wch: 20 }, // Name
  //     { wch: 15 }, // Designation
  //     { wch: 25 }, // PartnerName
  //     { wch: 20 }, // PartnerDesignation
  //     ...Array(sortedDates.length * 3).fill({ wch: 10 }),
  //   ];
  
  //   // Style TC, PC, Sales cells with yellow background
  //   const yellowStyle = {
  //     fill: {
  //       fgColor: { rgb: "FFFF00" },
  //     },
  //   };
  
  //   sortedDates.forEach((_, index) => {
  //     const startCol = 4 + index * 3; // Offset for initial columns and date group
  //     [startCol, startCol + 1, startCol + 2].forEach((col) => {
  //       const cellAddress = XLSX.utils.encode_cell({ r: 1, c: col });
  //       worksheet[cellAddress] = worksheet[cellAddress] || {};
  //       worksheet[cellAddress].s = yellowStyle;
  //     });
  //   });
  
  //   XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  //   XLSX.writeFile(workbook, "TransformedData.xlsx");
  // };
  
  const generateExcel = (id, apiData) => {
    // setIsDownloading(true)
    setCurrentId(id);
    
    const rows = [];
    const uniqueDates = new Set();
  
    // Step 1: Transform data
    apiData?.forEach((entry) => {
      const { first_name, partner_name, details, user_role, partner_role } = entry;
  
      details?.forEach(({ date, total_calls, productive_calls, final_total_cost }) => {
        uniqueDates.add(date);
  
        const existingRow = rows?.find(
          (row) => row.Name === first_name && row.PartnerName === partner_name
        );
  
        if (existingRow) {
          existingRow[`${date}_TC`] = total_calls;
          existingRow[`${date}_PC`] = productive_calls;
          existingRow[`${date}_Sales`] = final_total_cost;
        } else {
          const newRow = {
            Name: first_name,
            Designation: user_role,
            PartnerName: partner_name,
            PartnerDesignation: partner_role,
            [`${date}_TC`]: total_calls,
            [`${date}_PC`]: productive_calls,
            [`${date}_Sales`]: final_total_cost,
          };
          rows.push(newRow);
        }
      });
    });
  
    // Step 2: Create workbook and worksheet
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Report");
  
    // Add headers with merged cells for dates
    const sortedDates = Array?.from(uniqueDates).sort();
    const dateHeaders = sortedDates?.flatMap((date) => [date, null, null]);
    const subHeaders = sortedDates?.flatMap(() => ["TC", "PC", "Sales"]);
  
    // Add header rows
    worksheet.addRow([...Array(4).fill(null), ...dateHeaders]);
    worksheet.addRow(["Name", "Designation", "PartnerName", "PartnerDesignation", ...subHeaders]);
  
    // Merge cells for date headers
    sortedDates?.forEach((_, index) => {
      const startCol = 5 + index * 3; // Offset for initial columns and date group
      worksheet.mergeCells(1, startCol, 1, startCol + 2); // Merge date header cells
    });
  
    // Apply yellow background to dynamic date headers and subheaders
    const yellowStyle = {
      fill: {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" }, // Yellow background
      },
      font: { bold: true },
      alignment: { horizontal: "center", vertical: "middle" },
    };
  
    sortedDates?.forEach((_, index) => {
      const startCol = 5 + index * 3;
      for (let col = startCol; col <= startCol + 2; col++) {
        const dateHeaderCell = worksheet.getCell(1, col);
        dateHeaderCell.fill = yellowStyle.fill;
        dateHeaderCell.font = yellowStyle.font;
        dateHeaderCell.alignment = yellowStyle.alignment;
  
        const subHeaderCell = worksheet.getCell(2, col);
        subHeaderCell.fill = yellowStyle.fill;
        subHeaderCell.font = yellowStyle.font;
        subHeaderCell.alignment = yellowStyle.alignment;
      }
    });
  
    // Apply gray background to the first four headers
    const grayStyle = {
      fill: {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "D3D3D3" }, // Light gray background
      },
      font: { bold: true },
      alignment: { horizontal: "center", vertical: "middle" },
    };
  
    ["Name", "Designation", "PartnerName", "PartnerDesignation"].forEach((_, index) => {
      const headerCell = worksheet.getCell(2, index + 1); // Row 2, columns A-D
      headerCell.fill = grayStyle.fill;
      headerCell.font = grayStyle.font;
      headerCell.alignment = grayStyle.alignment;
    });
  
    // Step 3: Add rows for data
    rows.forEach((row) => {
      const rowData = [
        row.Name,
        row.Designation,
        row.PartnerName,
        row.PartnerDesignation,
        ...sortedDates.flatMap((date) => [
          row[`${date}_TC`] || "",
          row[`${date}_PC`] || "",
          row[`${date}_Sales`] || "",
        ]),
      ];
      worksheet.addRow(rowData);
    });
  
    // Adjust column widths
    worksheet.columns = [
      { width: 20 }, // Name
      { width: 15 }, // Designation
      { width: 25 }, // PartnerName
      { width: 20 }, // PartnerDesignation
      ...Array(sortedDates.length * 3).fill({ width: 10 }), // Dynamic date columns
    ];
  
    // Save the workbook
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "JointWorkingDataReport.xlsx";
      a.click();
      window.URL.revokeObjectURL(url);
    });
    // setIsDownloading(false);
  };


  // console.log(isDownloading, "isDownloading")
  
  
  
  
  const filteredDownloadName = userData?.role === "admin"
  ? downloadName
  : downloadName.filter(item => item.name !== "Retailers Report");

  const zoneData = useSelector((state) => state.app.curretDropdownData);
  const getRetailerDownloadData = useRetailerDownloadList(zoneData);
  const soPunchingData = useSOpunchingDetails(selectedData, zoneData);
  const asmPunchingData = useASMpunchingDetails(selectedData, zoneData);

  const getSRPerformanceMetrix = useSRPerformanceMetrix(selectedData, zoneData);
  const getAttendanceReport = useAttendanceReport(selectedData, zoneData);
  const getJointWorkingDetails = useJointWorkingDetails(selectedData, zoneData, setIsDownloading)
  const SalesDumpDownload = useSalesDumpDownload();

  const getAllSkuDetailsExcelDownload =
    useSkuDetailsExcelDownload(selectedData);
  const getAllSkuListExcelDownload = useSkuListExcelDownload();

  function getDaysInMonth(year, month) {
    return new Date(year, month + 1, 0).getDate();
  }

  const handleDownload = async (id) => {
    setCurrentId(id);

    const totalDays = getDaysInMonth(
      selectedData?.year,
      selectedData?.month_no
    );

    const headersAttendance = [
      "ASM Name",
      "SO Name",
      "SE Name",
      "punch in time",
      "punch out time",
    ];
    for (let i = 0; i < totalDays; i++) {
      headersAttendance.push(`Day ${i + 1}`);
    }

    if (id === 1) {
      setIsDownloading(true);
      setCurrentId(id);

      const headers = [
        "Sr No.",
        "Category",
        "SKU",
        "Total Units(PCS)",
        // "Total Units(ladi)",
        // "Total Units(carton)",
        "Total Sales",
      ];

      // Fetch the data from the database
      const data = await getAllSkuDetailsExcelDownload(); // Replace this with your actual data fetching logic

      const fromDate = new Date(selectedData.from).toLocaleDateString("en-GB"); // en-GB gives DD-MM-YYYY format
      const toDate = new Date(selectedData.to).toLocaleDateString("en-GB");

      // Create the date range string
      const dateRange = `Date Range: ${fromDate} - ${toDate}`;

      // Define the formatted data as before
      const formattedData = data.map((item, index) => ({
        "Sr No.": index + 1,
        Category: item.category_name,
        SKU: item.sku_name,
        "Total Units(PCS)": Number(item.total_pieces) || 0,
        // "Total Units(ladi)": Number(item.total_ladi_qty) || 0,
        // "Total Units(carton)": Number(item.total_carton_qty) || 0,
        "Total Sales": Number(item?.total_sales) || 0,
      }));

      // Calculate grand totals
      const grandTotal = formattedData.reduce(
        (acc, item) => ({
          "Total Units(PCS)":
            acc["Total Units(PCS)"] + (item["Total Units(PCS)"] || 0),
          // "Total Units(ladi)": acc["Total Units(ladi)"] + (item["Total Units(ladi)"] || 0),
          // "Total Units(carton)": acc["Total Units(carton)"] + (item["Total Units(carton)"] || 0),
          "Total Sales": acc["Total Sales"] + (item["Total Sales"] || 0),
        }),
        {
          "Total Units(PCS)": 0,
          // "Total Units(ladi)": 0,
          // "Total Units(carton)": 0,
          "Total Sales": 0,
        }
      );

      // Insert the new Date Range row into wsData
      const wsData = [
        ["", "", dateRange], // Add the Date Range row in the first position
        headers,
        ...formattedData.map((item) => headers.map((header) => item[header])),
        // Add grand total row
        [
          "",
          "",
          "Grand Total",
          grandTotal["Total Units(PCS)"],
          // grandTotal["Total Units(ladi)"],
          // grandTotal["Total Units(carton)"],
          grandTotal["Total Sales"],
        ],
      ];

      // Create the workbook and worksheet
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.aoa_to_sheet(wsData);

      // Define column widths
      const colWidths = headers.map((header, index) => {
        if (header === "Sr No.") return { wch: 10 };
        if (header === "Category" || header === "SKU") return { wch: 20 };
        if (
          header === "Price/PC" ||
          header === "Total Units(PCS)" ||
          // header === "Total Units(ladi)" ||
          // header === "Total Units(carton)" ||
          header === "Total Sales"
        )
          return { wch: 18 };
        return { wch: 25 }; // Default width
      });

      ws["!cols"] = colWidths;

      // Style the grand total row
      const lastRow = wsData.length;
      ["A", "B", "C", "D", "E"].forEach((col) => {
        const cell = ws[`${col}${lastRow}`];
        if (cell) {
          cell.s = {
            font: { bold: true },
            fill: { fgColor: { rgb: "FFFF00" } },
          };
        }
      });

      // Append worksheet to workbook and export the file
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      XLSX.writeFile(wb, "Productwise Sales Format.xlsx");
      setIsDownloading(false);
    } else if (id === 2) {
      setIsDownloading(true);
      setCurrentId(id);

      // Define the headers for the new Excel sheet
      const headers = [
        "Sr No.",
        "Category",
        "SKU",
        "Price/PC",
        "No of Pieces in Ladi",
        "No of Pieces in Carton",
        "Ladi Price",
        "No of Ladi in Carton",
        "Carton Price",
      ];

      // Fetch the data from the database
      const data = await getAllSkuListExcelDownload(); // Replace this with your actual data fetching logic

      // Map the database fields to the new headers
      const formattedData = data.map((item, index) => ({
        "Sr No.": index + 1,
        Category: item.category_name,
        SKU: item.sku_name,
        "Price/PC": item.price_per_piece,
        "No of Pieces in Ladi": item?.no_of_pcs_in_ladi,
        "No of Pieces in Carton": item?.no_of_piece_in_carton,
        // "Ladi Price": item?.price_per_ladi,
        "No of Ladi in Carton": item?.no_of_ladi_in_carton,
        // "Carton Price": item?.price_per_carton,
      }));

      const wb = XLSX.utils.book_new();
      const wsData = [
        headers,
        ...formattedData.map((item) => headers.map((header) => item[header])),
      ];

      const ws = XLSX.utils.aoa_to_sheet(wsData);

      // Define column widths
      const colWidths = headers.map((header, index) => {
        if (header === "Sr No.") return { wch: 10 };
        if (header === "Category" || header === "Product" || header === "SKU")
          return { wch: 20 };
        if (
          header === "Price/PC" ||
          header === "Ladi Price" ||
          header === "Carton Price"
        )
          return { wch: 10 };
        return { wch: 15 };
      });

      ws["!cols"] = colWidths;

      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      XLSX.writeFile(wb, "Product Catalogue.xlsx");
      setIsDownloading(false);
    } else if (id === 3) {
      setIsDownloading(true);
      const retailer_download = await getRetailerDownloadData(selectedData);

      // Define headers
      const headers = [
        { header: "#", key: "s_no", width: 5 },
        { header: "ASM Name", key: "asm_full_name", width: 25 },
        { header: "SO Name", key: "so_full_name", width: 25 },
        { header: "SR Name", key: "se_full_name", width: 30 },
        { header: "Retailer", key: "shop_name", width: 20 },
        { header: "Contact Person", key: "retailer_full_name", width: 30 },
        { header: "Contact Person Number", key: "mobile_no", width: 20 },
        { header: "Address", key: "address", width: 50 },
        { header: "Latitude", key: "lat", width: 15 },
        { header: "Longitude", key: "lng", width: 15 },
        { header: "Zone", key: "zone_name", width: 15 },
        { header: "State", key: "state_name", width: 15 },
        { header: "Employee Area", key: "area", width: 20 },
        { header: "Beat", key: "route_name", width: 20 },
        { header: "Route Day", key: "week", width: 30 },
        { header: "Pincode", key: "pincode", width: 30 },
        { header: "Distributor", key: "distributor", width: 30 },
        { header: "Credit Limit", key: "credit_limit", width: 15 },
        { header: "Credit Days", key: "credit_days", width: 15 },
        { header: "Retailer Code", key: "retailer_code", width: 20 },
        { header: "Created Date", key: "created_at", width: 25 },
        { header: "Created By", key: "onboarded_by", width: 30 },
      ];

      // Map data to include route details
      const worksheetData = retailer_download.map((item, index) => ({
        s_no: index + 1,
        asm_full_name: item.route_details[0]?.asm_full_name,
        so_full_name: item.route_details[0]?.so_full_name,
        se_full_name: item.route_details[0]?.se_full_name,
        shop_name: item.shop_name,
        retailer_full_name: item.retailer_full_name,
        mobile_no: item.mobile_no,
        address: item.address,
        lat: item.lat,
        lng: item.lng,
        zone_name: item.zone_name,
        state_name: item.state_name,
        area: item.route_details[0]?.area,
        route_name: item.route_details[0]?.route_name,
        week: item.route_details.map((route) => route.week).join(", "),
        distributor: item.route_details[0]?.dist_name,
        credit_limit: item.credit_limit ?? 0,
        credit_days: item.credit_days ?? 0,
        retailer_code: item.retailer_code,
        created_at: item.created_at,
        onboarded_by: item.onboarded_by,
      }));

      // Create worksheet
      const worksheet = XLSX.utils.json_to_sheet(worksheetData, {
        header: headers.map((h) => h.key),
      });

      // Set column widths
      worksheet["!cols"] = headers.map((h) => ({ wch: h.width }));

      // Apply bold style to headers and add header row
      const headerStyle = { font: { bold: true } };
      const headerRow = headers.map((h) => ({
        v: h.header,
        t: "s",
        s: headerStyle,
      }));
      XLSX.utils.sheet_add_aoa(worksheet, [headerRow], { origin: "A1" });

      // Create workbook and append worksheet
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "retailers_dump");

      // Write to file
      XLSX.writeFile(workbook, "retailer_dump.xlsx");
      setIsDownloading(false);
    } else if (id === 4) {
      setIsDownloading(true);
      const salesData = await SalesDumpDownload(selectedData, zoneData);
      // console.log(salesData)
      let previousSchemeNames = null;
      let previousBenefit = null;
      let previousBenefitQty = null;
      let previousSaleCode = null;
      let previousTotalQty = null;
      const processedSalesData = salesData.map((row) => {
        const saleCode = row.sale_code
        let items = row?.free_sku_quantity?.[0]?.split(", ");
        const values = {
          P: 1,
          L: row?.ladi,
          C: row?.ladi * row?.carton
        };

        let total = items?.reduce((sum, item) => {
          let [number, letter] = item.split(" ");
          number = parseInt(number); // Convert the number part to an integer
          sum += number * values[letter]; // Multiply by the corresponding value
          return sum;
        }, 0);
        const schemeNames = Array.isArray(row.scheme_names)
          ? row.scheme_names.join(", ")
          : row.scheme_names;
        const benefit = Array.isArray(row.benefit)
          ? row.benefit.join(", ")
          : row.benefit;
          const benefitQty = Array.isArray(row.free_sku_quantity)
          ? row.free_sku_quantity.join(", ")
          : row.free_sku_quantity;

        const newRow = {
          ...row,
          scheme_names: saleCode === previousSaleCode ? null : schemeNames,
          benefit: saleCode === previousSaleCode ? null : benefit,
          free_sku_quantity: saleCode === previousSaleCode ? null : benefitQty,
          total_free_sku_quantity: saleCode === previousSaleCode ? null : total
        };
        
        previousSchemeNames = schemeNames;
        previousBenefit = benefit;
        previousBenefitQty = benefitQty
        previousSaleCode = saleCode
        previousTotalQty = total

        return newRow;
      });

      if (salesData.length > 0) {
        const headers = [
          { header: "Country", key: "country", width: 15 },
          { header: "Zone", key: "zone_name", width: 20 },
          { header: "State", key: "state_name", width: 20 },
          { header: "ASM Code", key: "asm_code", width: 15 },
          { header: "ASM Name", key: "asm_name", width: 25 },
          { header: "SO Code", key: "so_code", width: 15 },
          { header: "SO Name", key: "so_name", width: 25 },
          { header: "Super Stockist", key: "ss_name", width: 25 },
          { header: "Distributor", key: "dist_name", width: 25 },
          { header: "SR Code", key: "sr_code", width: 20 },
          { header: "SR Name", key: "sr_name", width: 25 },
          { header: "Joint Working Name", key: "jwt_name", width: 25 },
          { header: "Joint Working Role", key: "jwt_role_name", width: 25 },
          { header: "Employee Area", key: "area", width: 20 },
          { header: "Day", key: "week", width: 15 },
          { header: "Route", key: "route_name", width: 20 },
          { header: "Retailer", key: "shop_name", width: 30 },
          { header: "Retailer Contact", key: "mobile_no", width: 20 },
          { header: "Retailer Address", key: "address", width: 50 },
          { header: "Order No.", key: "sale_code", width: 25 },
          { header: "Order Date", key: "order_date", width: 15 },
          { header: "Order Time", key: "order_time", width: 15 },
          { header: "Order Month", key: "order_month_abbr", width: 15 },
          { header: "Order Year", key: "order_year", width: 15 },
          { header: "Category", key: "category_name", width: 15 },
          { header: "SKU", key: "sku_name", width: 25 },
          { header: "Pieces Qty", key: "pieces_qty", width: 15 },
          { header: "Ladi Qty", key: "ladi_qty", width: 15 },
          { header: "Carton Qty", key: "carton_qty", width: 15 },
          { header: "Total Pieces", key: "total_pieces_sold", width: 15 },
          { header: "Pieces MRP", key: "pieces_mrp", width: 15 },
          { header: "Ladi MRP", key: "ladi_mrp", width: 15 },
          { header: "Carton MRP", key: "carton_mrp", width: 15 },
          { header: "Total Cost", key: "total_cost", width: 15 },
          { header: "Scheme Name", key: "scheme_names", width: 30 },
          { header: "Benefit", key: "benefit", width: 30 },
          { header: "Benefit Qty(in Pieces)", key: "total_free_sku_quantity", width: 20 },
          { header: "Billing Price", key: "final_total_cost", width: 20 },
          { header: "Order Latitude", key: "lat", width: 15 },
          { header: "Order Longitude", key: "lng", width: 15 },
          { header: "Status", key: "sale_status", width: 15 },
          { header: "Order Placed Via", key: "order_place", width: 20 },
          { header: "Visit Time", key: "order_date_time", width: 20 },
        ];

        // Create worksheet
        const worksheet = XLSX.utils.json_to_sheet(processedSalesData, {
          header: headers.map((h) => h.key),
        });

        // Set column widths
        worksheet["!cols"] = headers.map((h) => ({ wch: h.width }));

        // Apply bold style to headers and add header row
        const headerStyle = { font: { bold: true } };
        const headerRow = headers.map((h) => ({
          v: h.header,
          t: "s",
          s: headerStyle,
        }));
        XLSX.utils.sheet_add_aoa(worksheet, [headerRow], { origin: "A1" });

        // Create workbook and append worksheet
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sales Data");

        // Write to file
        XLSX.writeFile(workbook, "sales_data.xlsx");
      }
    } else if (id === 5) {
      setIsDownloading(true);
      const purchaseData = await soPunchingData();

      if (purchaseData.length > 0) {
        // Add serial number and extract first element of arrays for 'area' and 'route_name'
        purchaseData.forEach((item, index) => {
          item.s_no = index + 1;
          item.area = Array.isArray(item.area) ? item.area[0] : item.area;
          item.route_name = Array.isArray(item.route_name)
            ? item.route_name[0]
            : item.route_name;
        });

        const headers = [
          { header: "Sno", key: "s_no", width: 10 },
          { header: "Date", key: "work_date", width: 15 },
          { header: "SO Name", key: "so_name", width: 20 },
          { header: "Contact Number", key: "contact_number", width: 15 },
          { header: "State", key: "state_name", width: 15 },
          { header: "Zone", key: "zone_name", width: 15 },
          { header: "Employee Area", key: "area", width: 20 },
          { header: "Beat", key: "route_name", width: 20 },
          { header: "Punch In time", key: "first_punch_in", width: 15 },
          { header: "Punch Out time", key: "last_punch_out", width: 15 },
          { header: "Duration", key: "total_duration", width: 15 },
        ];

        const worksheet = XLSX.utils.json_to_sheet(purchaseData, {
          header: headers.map((h) => h.key),
        });

        // Set column widths
        worksheet["!cols"] = headers.map((h) => ({ wch: h.width }));

        // Apply bold style to headers and add header row
        const headerStyle = { font: { bold: true } };
        const headerRow = headers.map((h) => ({
          v: h.header,
          t: "s",
          s: headerStyle,
        }));
        XLSX.utils.sheet_add_aoa(worksheet, [headerRow], { origin: "A1" });

        // Create workbook and append worksheet
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "SO report");

        // Write to file
        XLSX.writeFile(workbook, "SO report.xlsx");
      }
    } else if (id === 8) {
      setIsDownloading(true);
      setCurrentId(id);

      // Define the new headers
      const headers = [
        "#",
        "ASM Name",
        "SO Name",
        "SR Name",
        "Emp_Area (Emp_Id)",
        "Date",
        "Call",
        "Productivity Call",
        "Time",
        "Sale",
        "Productivity",
      ];

      const data = await getSRPerformanceMetrix();

      // Map the static data to the new headers
      const formattedData = data.map((item, index) => ({
        "#": index + 1,
        "ASM Name": item.asm_name || "",
        "SO Name": item.so_name || "",
        "SR Name": item.sr_name,
        "Emp_Area (Emp_Id)": item.employee_area,
        Date: new Date(item.date).toLocaleDateString(),
        Call: item.total_calls,
        "Productivity Call": item.total_eco_calls,
        Time: item.total_time,
        Sale: item.total_sales,
        Productivity: item.productivity,
      }));

      const wb = XLSX.utils.book_new();
      const wsData = [
        headers,
        ...formattedData.map((item) => headers.map((header) => item[header])),
      ];

      const ws = XLSX.utils.aoa_to_sheet(wsData);

      // Define column widths
      const colWidths = headers.map((header) => {
        if (header === "#") return { wch: 5 };
        if (header === "Date") return { wch: 15 };
        if (header === "SR Name") return { wch: 40 };
        if (
          header === "Call" ||
          header === "Productivity Call" ||
          header === "Time" ||
          header === "Sale" ||
          header === "Productivity"
        )
          return { wch: 15 };
        return { wch: 20 }; // Default width
      });

      ws["!cols"] = colWidths;

      XLSX.utils.book_append_sheet(wb, ws, "SR_Performance_matrix");
      XLSX.writeFile(wb, "SR_Performance_matrix.xlsx");

      setIsDownloading(false);
    } else if (id === 7) {
      await getAttendanceDatav2(id, selectedData.from, selectedData.to);
    } else if (id === 9) {
      await getAttendanceDataHRv2(id, selectedData.from, selectedData.to);
    } else if(id === 10) {
      console.log("10Id Clicked", id)
      const apiData = await getJointWorkingDetails()
      if (apiData?.length > 0) {
        // Call the function to generate Excel
        generateExcel(id, apiData);
      }
    }
    
    else {
      setIsDownloading(true);
      const purchaseData = await asmPunchingData();

      if (purchaseData.length > 0) {
        // Add serial number and extract first element of arrays for 'area' and 'route_name'
        purchaseData.forEach((item, index) => {
          item.s_no = index + 1;
          item.area = Array.isArray(item.area) ? item.area[0] : item.area;
          item.route_name = Array.isArray(item.route_name)
            ? item.route_name[0]
            : item.route_name;
        });

        const headers = [
          { header: "Sno", key: "s_no", width: 10 },
          { header: "Date", key: "work_date", width: 15 },
          { header: "ASM Name", key: "asm_name", width: 20 },
          { header: "Contact Number", key: "contact_number", width: 15 },
          { header: "State", key: "state_name", width: 15 },
          { header: "Zone", key: "zone_name", width: 15 },
          { header: "Employee Area", key: "area", width: 20 },
          { header: "Beat", key: "route_name", width: 20 },
          { header: "Punch In time", key: "first_punch_in", width: 15 },
          { header: "Punch Out time", key: "last_punch_out", width: 15 },
          { header: "Duration", key: "total_duration", width: 15 },
        ];

        const worksheet = XLSX.utils.json_to_sheet(purchaseData, {
          header: headers.map((h) => h.key),
        });

        // Set column widths
        worksheet["!cols"] = headers.map((h) => ({ wch: h.width }));

        // Apply bold style to headers and add header row
        const headerStyle = { font: { bold: true } };
        const headerRow = headers.map((h) => ({
          v: h.header,
          t: "s",
          s: headerStyle,
        }));
        XLSX.utils.sheet_add_aoa(worksheet, [headerRow], { origin: "A1" });

        // Create workbook and append worksheet
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "ASM report");

        // Write to file
        XLSX.writeFile(workbook, "ASM report.xlsx");
      }
    }
    setIsDownloading(false);
  };

  const getAttendanceData = async (id) => {
    setIsDownloading(true);
    setCurrentId(id);

    // Define the headers for the Excel file
    const headers = [
      "#",
      "ASM Name",
      "SO Name",
      "Emp. Id",
      "Employee",
      "Designation",
      "Branch/Departement",
      "Punch In Date",
      "Punch In Time",
      "Punch Out Date",
      "Punch Out Time",
      "Working Hours",
      "productive Hours",
      "Extra Hours",
      "Less Hours",
      "Shift Hours",
      "On Leave",
      "Late In",
      "Early Out",
      "Out of Range (In)",
      "Out of Range (Out)",
      "Is Modified",
      "Status",
      "In Branch",
      "Out Branch",
      "Offline Sync",
      "Shift",
      "Punch In location",
      "Punch Out location",
      "Late in reason",
      "Early out reason",
      "Punch In Out of range Reason",
      "Punch Out Out of range Reason",
      "GPS Travel KM",
    ];

    // Fetch the data from the database (replace this with your actual data fetching logic)
    const data = await getAttendanceReport(); // Assume this is your API call

    console.log(data, "getAttendanceReport");

    // Map the database fields to the new headers
    const formattedData = data?.map((item, index) => ({
      "#": index + 1,
      "ASM Name": item.asm_name || "",
      "SO Name": item.so_name || "",
      "Emp. Id": item.route_area || "",
      Employee: item.employee_name || "",
      Designation: item.user_role_name || "",
      "Branch/Departement": item.zone_name || "",
      "Punch In Date": item.punch_in_date || "",
      "Punch In Time": item.punch_in_time || "",
      "Punch Out Date": item.punch_out_date || "",
      "Punch Out Time": item.punch_out_time || "",
      "Working Hours": item.total_duration || "",
      "productive Hours": item.productive_hours || "",
      "Extra Hours": item.extra_duration || "",
      "Less Hours": item.remaining_duration || "",
      "Shift Hours": item.shift_hours?.hours || "",
      "On Leave": item.on_leave || "",
      "Late In": item.late_in || "",
      "Early Out": item.early_out || "",
      "Out of Range (In)": item.out_of_range_in || "",
      "Out of Range (Out)": item.out_of_range_out || "",
      "Is Modified": item.is_modified || "",
      Status: item.status || "",
      "In Branch": item.in_branch || "",
      "Out Branch": item.out_branch || "",
      "Offline Sync": item.offline_sync || "",
      Shift: item.shift || "",
      "Punch In location": item.punch_in_location || "",
      "Punch Out location": item.punch_out_location || "",
      "Late in reason": item.late_in_reason || "",
      "Early out reason": item.early_out_reason || "",
      "Punch In Out of range Reason": item.punch_in_out_of_range_reason || "",
      "Punch Out Out of range Reason": item.punch_out_out_of_range_reason || "",
      "GPS Travel KM": item.distance || "0.00",
    }));

    // Create a new workbook and worksheet
    const wb = XLSX.utils.book_new();
    const wsData = [
      headers,
      ...formattedData.map((item) => headers.map((header) => item[header])),
    ];

    const ws = XLSX.utils.aoa_to_sheet(wsData);

    // Define column widths
    const colWidths = headers.map((header, index) => {
      if (header === "#") return { wch: 5 };
      if (header === "Emp. Id" || header === "GPS Travel KM")
        return { wch: 15 };
      if (
        header === "Punch In Date" ||
        header === "Punch In Time" ||
        header === "Punch Out Date" ||
        header === "Punch Out Time"
      )
        return { wch: 20 };
      return { wch: 25 }; // Default width for other columns
    });

    ws["!cols"] = colWidths;

    // Append the worksheet and write the file
    XLSX.utils.book_append_sheet(wb, ws, "User Details");
    XLSX.writeFile(wb, "Userwise Attendance.xlsx");

    setIsDownloading(false);
  };

  function convertDate(inputDate) {
    // Remove the weekday from the input string
    const cleanDate = inputDate.split(' ')[0]; // "12/9/2024"
  
    // Parse the date string into a Date object
    const dateParts = cleanDate.split('/'); // [12, 9, 2024]
    const day = dateParts[0];
    const month = dateParts[1];
    const year = dateParts[2];
  
    // Create a new date object
    const date = new Date(year, month - 1, day); // Month is 0-indexed
  
    // Format the date as "YYYY-M-D"
    const formattedDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    return formattedDate;
  }

  const getAttendanceDatav2 = async (id, fromDate, toDate) => {
    setIsDownloading(true);
    setCurrentId(id);

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Report");

    // Freeze panes
    worksheet.views = [
      {
        state: "frozen",
        xSplit: 5, // Fixed columns up to "SR Name"
        ySplit: 2, // Fixed rows for headers
      },
    ];

    // Add fixed headers
    const fixedHeaders = [
      "#",
      "ASM Name",
      "SO Name",
      "Employee Area",
      "SR Name",
      "Designation",
      "Zone",
    ];

    fixedHeaders.forEach((header, index) => {
      const startCol = index + 1;
      const headerCell = worksheet.getCell(1, startCol);
      headerCell.value = header;
      headerCell.alignment = { vertical: "middle", horizontal: "center" };
      headerCell.font = { bold: true };
      headerCell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" },
      }; // Yellow background
      headerCell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    
      // Merge row 1 and row 2 for fixed headers
      worksheet.mergeCells(1, startCol, 2, startCol);
    });
    
    const startDate = new Date(fromDate);
    const endDate = new Date(toDate);
    const dates = [];

    for (
      let date = startDate;
      date <= endDate;
      date.setDate(date.getDate() + 1)
    ) {
      const dayName = date.toLocaleDateString("en-US", { weekday: "short" });
      // const formattedDate = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()} (${dayName})`;
      const formattedMonth = String(date.getMonth() + 1).padStart(2, "0");
      const formattedDay = String(date.getDate()).padStart(2, "0");

      // Format the date as "YYYY-MM-DD (DayName)"
      const formattedDate = `${date.getFullYear()}-${formattedMonth}-${formattedDay} (${dayName})`;
      // const formattedDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} (${dayName})`;
      dates.push({ date: formattedDate, subHeaders: ["Att.", "TS", "PC"] });
    }

    let currentCol = fixedHeaders.length + 1;
    dates?.forEach(({ date, subHeaders }) => {
      const [formattedDate, dayName] = date.split(" ");
      const startCol = currentCol;
      const endCol = currentCol + subHeaders.length - 1;
    
      // Merge horizontally for row 1 and row 2 separately
      worksheet.mergeCells(1, startCol, 1, endCol);
      worksheet.mergeCells(2, startCol, 2, endCol);
    
      // Set the date in row 1
      const dateCell = worksheet.getCell(1, startCol);
      dateCell.value = formattedDate; // e.g., "2024-12-10"
      dateCell.alignment = { vertical: "middle", horizontal: "center" };
      dateCell.font = { bold: true };
    
      // Set the day name in row 2
      const dayCell = worksheet.getCell(2, startCol);
      dayCell.value = dayName; // e.g., "(Tue)"
      dayCell.alignment = { vertical: "middle", horizontal: "center" };
      dayCell.font = { italic: true }; // Optional styling
    
      // Add subHeaders in row 3
      subHeaders.forEach((subHeader, index) => {
        const subHeaderCell = worksheet.getCell(3, startCol + index);
        subHeaderCell.value = subHeader;
        subHeaderCell.alignment = { vertical: "middle", horizontal: "center" };
        subHeaderCell.font = { bold: true };
        subHeaderCell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFFF00" },
        }; // Yellow background
        subHeaderCell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      });
    
      currentCol = endCol + 1;
    });
    

    // Fetch the data
    const user_list = await getAttendanceReport();
    console.log(user_list, "userList");

    // Populate rows with data
    user_list?.forEach((user, index) => {
      const rowData = [
        index + 1, // Serial number
        user.asm_name,
        user.so_name,
        user.route_area || "",
        user.sr_name,
        user.designation,
        user.zone_name,
      ];

      // Append daily stats for each date
      dates?.forEach(({ date }, i) => {
        const stats = user.daily_stats.find(
          (stat) => stat.date === date.split(" ")[0]
        );
        rowData.push(
          stats ? stats.attendance_status : "",
          stats ? stats.distance_covered : "",
          stats ? stats.call_count : ""
        );
      });

      worksheet.addRow(rowData);
    });

    // Adjust column widths
    // worksheet.columns = Array(currentCol).fill({ width: 15 });
    // Set custom column widths
    worksheet.columns = [
      { width: 5 }, // "#"
      { width: 20 }, // "ASM Name"
      { width: 20 }, // "SO Name"
      { width: 15 }, // "Employee Area"
      { width: 20 }, // "SR Name"
      { width: 15 }, // "Designation"
      { width: 12 }, // "Zone"
      ...Array(dates?.length * 3).fill({ width: 8 }), // Dynamic columns for "Att.", "TS", "PC"
    ];

    // Save the file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "Userwise Attendance.xlsx";
    a.click();
    window.URL.revokeObjectURL(url);

    setIsDownloading(false);
  };

  const getAttendanceDataHRv2 = async (id, fromDate, toDate) => {
    setIsDownloading(true);
    setCurrentId(id);

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Report");

    // Freeze panes
    worksheet.views = [
      {
        state: "frozen",
        xSplit: 5, // Fixed columns up to "SR Name"
        ySplit: 2, // Fixed rows for headers
      },
    ];

    // Add fixed headers
    const fixedHeaders = [
      "#",
      "ASM Name",
      "SO Name",
      "Employee Area",
      "SR Name",
      "Designation",
      "Zone",
    ];

    fixedHeaders.forEach((header, index) => {
      const startCol = index + 1;
      const headerCell = worksheet.getCell(1, startCol);
      headerCell.value = header;
      headerCell.alignment = { vertical: "middle", horizontal: "center" };
      headerCell.font = { bold: true };
      headerCell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" },
      }; // Yellow background
      headerCell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    
      // Merge row 1 and row 2 for fixed headers
      worksheet.mergeCells(1, startCol, 2, startCol);
    });
    
    const startDate = new Date(fromDate);
    const endDate = new Date(toDate);
    const dates = [];

    for (
      let date = startDate;
      date <= endDate;
      date.setDate(date.getDate() + 1)
    ) {
      const dayName = date.toLocaleDateString("en-US", { weekday: "short" });
      // const formattedDate = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()} (${dayName})`;
      const formattedMonth = String(date.getMonth() + 1).padStart(2, "0");
      const formattedDay = String(date.getDate()).padStart(2, "0");

      // Format the date as "YYYY-MM-DD (DayName)"
      const formattedDate = `${date.getFullYear()}-${formattedMonth}-${formattedDay} (${dayName})`;
      // const formattedDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} (${dayName})`;
      dates.push({ date: formattedDate, subHeaders: ["Att."] });
    }

    let currentCol = fixedHeaders.length + 1;
    dates?.forEach(({ date, subHeaders }) => {
      const [formattedDate, dayName] = date.split(" ");
      const startCol = currentCol;
      const endCol = currentCol + subHeaders.length - 1;
    
      // Merge horizontally for row 1 and row 2 separately
      worksheet.mergeCells(1, startCol, 1, endCol);
      worksheet.mergeCells(2, startCol, 2, endCol);
    
      // Set the date in row 1
      const dateCell = worksheet.getCell(1, startCol);
      dateCell.value = formattedDate; // e.g., "2024-12-10"
      dateCell.alignment = { vertical: "middle", horizontal: "center" };
      dateCell.font = { bold: true };
    
      // Set the day name in row 2
      const dayCell = worksheet.getCell(2, startCol);
      dayCell.value = dayName; // e.g., "(Tue)"
      dayCell.alignment = { vertical: "middle", horizontal: "center" };
      dayCell.font = { italic: true }; // Optional styling
    
      // Add subHeaders in row 3
      subHeaders.forEach((subHeader, index) => {
        const subHeaderCell = worksheet.getCell(3, startCol + index);
        subHeaderCell.value = subHeader;
        subHeaderCell.alignment = { vertical: "middle", horizontal: "center" };
        subHeaderCell.font = { bold: true };
        subHeaderCell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFFF00" },
        }; // Yellow background
        subHeaderCell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      });
    
      currentCol = endCol + 1;
    });

    // Fetch the data
    const user_list = await getAttendanceReport();

    // Populate rows with data
    user_list?.forEach((user, index) => {
      const rowData = [
        index + 1, // Serial number
        user.asm_name,
        user.so_name,
        user.route_area || "",
        user.sr_name,
        user.designation,
        user.zone_name,
      ];

      // Append daily stats for each date
      dates?.forEach(({ date }, i) => {
        const stats = user.daily_stats.find(
          (stat) => stat.date === date.split(" ")[0]
        );
        rowData.push(
          stats ? stats.attendance_status : ""
        );
      });

      worksheet.addRow(rowData);
    });

    // Adjust column widths
    // worksheet.columns = Array(currentCol).fill({ width: 15 });
    // Set custom column widths
    worksheet.columns = [
      { width: 5 }, // "#"
      { width: 20 }, // "ASM Name"
      { width: 20 }, // "SO Name"
      { width: 15 }, // "Employee Area"
      { width: 20 }, // "SR Name"
      { width: 15 }, // "Designation"
      { width: 12 }, // "Zone"
      ...Array(dates?.length * 1).fill({ width: 12 }), // Dynamic columns for "Att."
    ];

    // Save the file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "Userwise Attendance(HR).xlsx";
    a.click();
    window.URL.revokeObjectURL(url);

    setIsDownloading(false);
  };


  return (
    <Stack
      sx={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        gap: "1.5rem",
      }}
    >
      {isDownloading ? <DownloadLoader /> : null}
      {filteredDownloadName?.map((download) => (
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            background: "#FFFFFF",
            boxShadow: 3,
            width: "22rem",
            padding: "1rem",
            borderRadius: "0.6rem",
            justifyContent: "space-between",
          }}
        >
          <Typography>{download?.name}</Typography>
          <Stack sx={{ display: "flex", flexDirection: "row", gap: "0.5rem" }}>
            {/* <Stack
              sx={{
                border: "1px solid #EF6C1A",
                padding: "0.2rem",
                borderRadius: "0.2rem",
              }}
            >
              <DeleteIcon sx={{ color: "#EF6C1A" }} />
            </Stack> */}
            <Stack
              sx={{
                border: "1px solid #EF6C1A",
                padding: "0.2rem",
                borderRadius: "0.2rem",
              }}
            >
              <DownloadIcon
                sx={{ color: "#EF6C1A", cursor: "pointer" }}
                onClick={() => handleDownload(download?.id)}
              />
            </Stack>
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
};

export default DownloadFile;
