import * as React from "react";
import { useSelector } from "react-redux";

import Paper from "@mui/material/Paper";
import { Stack, Typography, Button, Chip, Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import Switch from "@mui/material/Switch";

import SalesChart from "./SalesChart";
import { ToastContainer } from "react-toastify";
import RetailerPopup from "../Dashboard/RetailerPopUp";

import useEditScheme from "../../hooks/useEditScheme";
import useDeletedScheme from "../../hooks/useDeletedScheme";
import useSchemeSalesData from "../../hooks/useSchemeSalesData";
import useSchemeDetailsList from "../../hooks/useSchemeDetailsList";
import useUpdateSchemeStatus from "../../hooks/useUpdateSchemeStatus";
import useSelectedRetailerList from "../../hooks/useSelectedRetailerList";

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 12,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor:
          theme.palette.mode === "dark" ? "#177ddc" : "#009C19",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

export default function SchemeManagementTable({dropDown}) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [showGraph, setShowGraph] = React.useState(false);
  const [schemeDetails, setSchemeDetails] = React.useState({});
  const [popupOpen, setPopupOpen] = React.useState(false);
  const [retailerList, setRetailerList] = React.useState([]);
  const [scheme_id, setSchemeId] = React.useState(null);
  const [scheme_type, setSchemeType] = React.useState(null);
  const [sg_id, setSgId] = React.useState(null);
  const [sku_id, setSkuId] = React.useState(null);
  const [status, setStatus] = React.useState(null);
  const [open, setOpen] = React.useState({});
  const row = [
    "SI No.",
    "Scheme",
    "Scheme Type",
    "No. of SKUs",
    "Validity",
    "Scope",
    "Date of Creation",
    "Action",
    "",
  ];
  
  //----------API Calls---------------
  const updateSchemeStatus = useUpdateSchemeStatus();
  const updateSchemeDetailsList = useSchemeDetailsList();
  const getSchemeSalesData = useSchemeSalesData();
  const delteScheme = useDeletedScheme();
  const editScheme = useEditScheme();
  const getRetailersDetails = useSelectedRetailerList();
  //----------API Calls---------------

  //----------Redux---------------
  const tableDetails = useSelector((store) => store.scheme.schemeList);
  const zoneData = useSelector((state) => state.app.curretDropdownData);
  //----------Redux---------------
  
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
 
  const showSchemeDetails = async(data) => {
    setShowGraph(true);
    
    setSchemeDetails(data);
    await getSchemeSalesData(data);
    document
      .getElementById("SchemeGraphDetails")
      .scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
  };

  const handleDateChange = (e, dateType) => {
    const value = e.target.value;
    const updatedSchemeDetails = { ...schemeDetails };

    if (dateType === 'start_date') {
      updatedSchemeDetails.start_date = value;
      updatedSchemeDetails.end_date=new Date(schemeDetails?.end_date).toISOString().split('T')[0];
    } else if (dateType === 'end_date') {
      updatedSchemeDetails.end_date = value;
      updatedSchemeDetails.start_date=new Date(schemeDetails?.start_date).toISOString().split('T')[0];
    }

    setSchemeDetails(updatedSchemeDetails);
  };
  const handleStatusChange = async (sku_id, scheme_id, scheme_type, event, sg_id) => {
    const param = {
      scheme_id: scheme_id,
      scheme_type: scheme_type,
      status: event.target.checked,
      sg_id: sg_id
    };
    const param2 = {
      sku_id: dropDown?.sku_id,
      status: dropDown?.status===true?'active':'inactive',
      sc_id:dropDown?.sc_id,
      zone_id: zoneData?.zone_id
    };
    await updateSchemeStatus(param);
    await updateSchemeDetailsList(param2);
  };

  const handleDeleteClick=(skuId, schemeId, schemeType, status, sgId)=>{
    setSkuId(skuId);
    setSchemeId(schemeId);
    setSchemeType(schemeType);
    setStatus(status);
    setSgId(sgId);
    setOpen((prev) => {
      return { ...prev, [sgId]: true };
    });
  }
  const handleConfirmDelete = async() => {
    const param = {
      scheme_id: scheme_id,
      scheme_type: scheme_type,
      status: 'true',
      sg_id: sg_id,
    };
    const param2 = {
      sku_id: dropDown?.sku_id,
      status: dropDown?.status === true ? 'active' : 'inactive',
      sc_id: dropDown?.sc_id,
      zone_id: zoneData?.zone_id,
    };

    await delteScheme(param);
    await updateSchemeDetailsList(param2);
    // Close the dialog after successful deletion
    setOpen(false);
  };
  const handleCloseDialog = () => {
    setOpen(false);
  };

  const updateScheme = async() =>{
    const param2 = {
      sku_id: dropDown?.sku_id,
      status: dropDown?.status===true?'active':'inactive',
      sc_id:dropDown?.sc_id,
      zone_id: zoneData?.zone_id
    };
    await editScheme(schemeDetails);
    await updateSchemeDetailsList(param2);
  }
  const handleRetailerPopUp = async(retailers_id) =>{
    setPopupOpen(true);
    const param ={
      retailer_id:retailers_id
    }
   const resposnse = await getRetailersDetails(param);
    setRetailerList(resposnse.body);
  }
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });
    return `${day}-${month}`;
  };
  const CardFormatDate = (isoDate) => {
    const date = new Date(isoDate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  return (
    <Stack sx={{ flexDirection: "coulmn", gap: "8px" }}>
      <Paper sx={{ width: "100%" }}>
        <ToastContainer
          position="bottom-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <TableContainer
          sx={{ maxHeight: 500, fontFamily: "Inter, sans-serif" }}
        >
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {row?.map(
                  (
                    item,
                    index // Added index to map function arguments
                  ) => (
                    <TableCell
                      key={index} // Added key prop with unique value
                      sx={{
                        background: "#F8F8F8",
                        fontSize: "14px",
                        color: "#5F6868",
                        fontWeight: "500",
                        color: "#5F6868",
                      }}
                      align="start"
                      colSpan={1}
                    >
                      {item}
                    </TableCell>
                  )
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {tableDetails
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((data, index) => {
                  const prevData = tableDetails[page * rowsPerPage + index - 1];
                  const nextData = tableDetails[page * rowsPerPage + index + 1];
                  const showCampaignName =
                    !prevData ||
                    prevData.campaign_name !== data.campaign_name ||
                    data.campaign_name === null;
                  const sameAsNextCampaignName =
                    nextData && nextData.campaign_name === data.campaign_name;
                  return (
                    <>
                      <TableRow
                        sx={{
                          borderBottom: sameAsNextCampaignName
                            ? "none"
                            : "1px solid rgba(224, 224, 224, 1)",
                        }}
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={data.id}
                      >
                        {/* Render SO Users */}
                        <TableCell
                          sx={{
                            color: "#5F6868",
                            borderBottom: sameAsNextCampaignName
                              ? "none"
                              : "1px solid rgba(224, 224, 224, 1)",
                          }}
                          align="start"
                          colSpan={1}
                        >
                          {index + 1}
                        </TableCell>
                        {/* <TableCell
                          sx={{
                            color: "#5F6868",
                            borderBottom: sameAsNextCampaignName
                              ? "none"
                              : "1px solid rgba(224, 224, 224, 1)",
                          }}
                          align="start"
                          colSpan={1}
                        >
                          <Typography
                            sx={{
                              color: "#5F6868",
                              cursor: "pointer",
                              ":hover": {
                                color: "#0B5ACF",
                                textDecoration: "underline",
                              },
                            }}
                            onClick={() => handleCampaignClick(data)}
                          >
                            {showCampaignName
                              ? data?.campaign_name ?? "- -"
                              : ""}
                          </Typography>
                        </TableCell> */}
                        <TableCell
                          sx={{
                            color: "#5F6868",
                            borderBottom: sameAsNextCampaignName
                              ? "none"
                              : "1px solid rgba(224, 224, 224, 1)",
                          }}
                          align="start"
                          colSpan={1}
                        >
                          <React.Fragment key={index}>
                            {data?.scheme_name}
                          </React.Fragment>
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#5F6868",
                            borderBottom: sameAsNextCampaignName
                              ? "none"
                              : "1px solid rgba(224, 224, 224, 1)",
                            width:"8rem"
                          }}
                          align="start"
                          colSpan={1}
                        >
                          <React.Fragment key={index}>
                            {data?.scheme_type_id === 1
                              ? " Discount Scheme"
                              : data?.scheme_type_id === 2
                              ? " Quantity Purchase Exclusive"
                              : data?.scheme_type_id === 4
                              ? " Quantity Purchase Inclusive"
                              : " Text"}
                          </React.Fragment>
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#5F6868",
                            borderBottom: sameAsNextCampaignName
                              ? "none"
                              : "1px solid rgba(224, 224, 224, 1)",
                          }}
                          align="start"
                          colSpan={1}
                        >
                          {data?.sku_count}
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#5F6868",
                            borderBottom: sameAsNextCampaignName
                              ? "none"
                              : "1px solid rgba(224, 224, 224, 1)",
                          }}
                          align="start"
                          colSpan={1}
                        >
                          {formatDate(data?.start_date)} to{" "}
                          {formatDate(data?.end_date)}
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#5F6868",
                            borderBottom: sameAsNextCampaignName
                              ? "none"
                              : "1px solid rgba(224, 224, 224, 1)",
                          }}
                          align="start"
                          colSpan={1}
                        >
                          {data?.scheme_scope}
                        </TableCell>

                        <TableCell
                          sx={{
                            color: "#5F6868",
                            borderBottom: sameAsNextCampaignName
                              ? "none"
                              : "1px solid rgba(224, 224, 224, 1)",
                          }}
                          align="start"
                          colSpan={1}
                        >
                          {formatDate(data?.created_at)}
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#5F6868",
                            borderBottom: sameAsNextCampaignName
                              ? "none"
                              : "1px solid rgba(224, 224, 224, 1)",
                          }}
                          align="start"
                          colSpan={1}
                        >
                          <Stack
                            sx={{
                              flexDirection: "row",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            <AntSwitch
                              checked={data.status === "active" ? true : false}
                              onChange={(event) =>
                                handleStatusChange(
                                  data?.sku_id,
                                  data?.scheme_id,
                                  data?.scheme_type,
                                  event,
                                  data?.sg_id
                                )
                              }
                              inputProps={{ "aria-label": "controlled" }}
                            />
                            <DeleteIcon
                              sx={{ color: "#EF6C1A", cursor: "pointer" }}
                              onClick={() =>
                                handleDeleteClick(
                                  data?.sku_id,
                                  data?.scheme_id,
                                  data?.scheme_type,
                                  data?.status,
                                  data?.sg_id
                                )
                              }
                            />
                          </Stack>
                        </TableCell>

                        <TableCell
                          sx={{
                            color: "#5F6868",
                            borderBottom: sameAsNextCampaignName
                              ? "none"
                              : "1px solid rgba(224, 224, 224, 1)",
                          }}
                          align="start"
                          colSpan={1}
                        >
                          <Button
                            sx={{
                              color: "#EF6C1A !important",
                              backgroundColor: "white !important",
                              "&:hover": {
                                backgroundColor: "#EF6C1A !important",
                                color: "white !important",
                                border: "2px solid #EF6C1A !important",
                              },
                              border: "2px solid #EF6C1A !important",
                              height: "30px !important",
                              fontSize: "14px !important",
                              borderRadius: "5px !important",
                              textTransform: "none",
                            }}
                            onClick={() => showSchemeDetails(data)}
                          >
                            Details
                          </Button>
                        </TableCell>
                      </TableRow>
                    </>
                  );
                })}
            </TableBody>
            <Dialog open={open[sg_id] || false} onClose={handleCloseDialog}>
              <DialogTitle>Confirm Delete</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Are you sure you want to delete this scheme? This action
                  cannot be undone.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseDialog} color="primary">
                  Cancel
                </Button>
                <Button onClick={handleConfirmDelete} color="primary">
                  Confirm
                </Button>
              </DialogActions>
            </Dialog>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={tableDetails?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      {showGraph ? (
        <Stack
          sx={{
            padding: "20px",
            backgroundColor: "#fff",
            borderRadius: "8px",
            gap: "10px",
          }}
        >
          <Stack sx={{ flexDirection: "column" }}>
            <Stack sx={{ width: "100%" }}>
              <Stack
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Typography
                  sx={{
                    textAlign: "left",
                    fontSize: "20px",
                    fontWeight: "700",
                  }}
                >
                  {schemeDetails?.scheme_name} |{" "}
                  <span style={{ fontWeight: "400" }}>
                    {schemeDetails?.qty ? (
                      <span>
                        {" "}
                        Total SKUs : {schemeDetails?.sku_name?.length}
                      </span>
                    ) : null}
                  </span>
                </Typography>

                <Stack
                  sx={{
                    flexDirection: "row",
                    gap: "8px",
                    alignItems: "center",
                  }}
                >
                  <Stack
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "1rem",
                    }}
                  >
                    <label>From :</label>
                    <input
                      type="date"
                      id="dateSale"
                      name="start_date"
                      style={{
                        height: "2rem",
                        fontSize: "14px",
                        padding: "4px",
                        cursor: "pointer",
                        borderRadius: "4px",
                        border: "1px solid #CDCFD4",
                      }}
                      value={CardFormatDate(schemeDetails?.start_date)}
                      onChange={(e) => handleDateChange(e, "start_date")}
                    />
                  </Stack>
                  <Stack
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "1rem",
                    }}
                  >
                    <label>To :</label>
                    <input
                      type="date"
                      id="dateSale"
                      name="end_date"
                      style={{
                        height: "2rem",
                        fontSize: "14px",
                        padding: "4px",
                        cursor: "pointer",
                        borderRadius: "4px",
                        border: "1px solid #CDCFD4",
                      }}
                      value={CardFormatDate(schemeDetails?.end_date)}
                      onChange={(e) => handleDateChange(e, "end_date")}
                    />
                  </Stack>
                  <Typography
                    onClick={() => updateScheme()}
                    sx={{
                      color: "rgba(255, 90, 90, 1)",
                      fontSize: "14px",
                      fontWeight: "700",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                  >
                    Update
                  </Typography>
                </Stack>
              </Stack>

              <Typography
                sx={{
                  textAlign: "left",
                  fontSize: "20px",
                  fontWeight: "400",
                }}
              >
                Scope:{" "}
                {schemeDetails?.scheme_type === "outlets" ? (
                  <span
                    style={{
                      fontWeight: "700",
                      textDecoration: "underline",
                      color: "#1A56EF",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      handleRetailerPopUp(schemeDetails.retailer_id);
                    }}
                  >
                    Key Retailers
                  </span>
                ) : (
                  <span style={{ fontWeight: "400" }}>
                    {schemeDetails?.scheme_type} &gt;{" "}
                  </span>
                )}
                <RetailerPopup
                  isOpen={popupOpen}
                  onClose={() => setPopupOpen(false)}
                  retailerData={retailerList}
                />
                <span style={{ fontWeight: "400" }}>
                  {schemeDetails?.scheme_scope
                    ? schemeDetails.scheme_scope
                    : schemeDetails?.zone_name}
                </span>
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "0.8rem",
                  alignItems: "center",
                  marginTop: "0.5rem",
                  flexWrap: "wrap",
                }}
              >
                <Typography sx={{ fontSize: "20px" }}>SKUs :</Typography>
                {schemeDetails?.sku_name?.map((sku, index) => (
                  <Chip
                    key={index} // Ideally, use a unique identifier for each SKU
                    label={sku}
                    // onDelete={() => handleDelete(sku)} // Function to handle deletion
                    style={{ backgroundColor: "#f0f0f0", color: "#333" }}
                  />
                ))}
              </Box>
            </Stack>
          </Stack>
          <Stack
            sx={{
              flexDirection: "row",
              alignItems: "start",
              flexWrap: "wrap",
              marginTop: "1rem",
            }}
          >
            <Typography>
              <span style={{ fontWeight: "600" }}>Scheme Type: </span>
              {schemeDetails?.scheme_type_id === 1
                ? " Discount"
                : schemeDetails?.scheme_type_id === 2
                ? " Free" 
                : schemeDetails?.scheme_type_id === 4
                ? " Free Inclusive"
                : " Text"
                }
            </Typography>
            {schemeDetails?.discount_percentage ? (
              <Typography>
                <span style={{ fontWeight: "600" }}>Discount:</span>{" "}
                {schemeDetails?.discount_percentage}%
              </Typography>
            ) : null}

            {/* {schemeDetails?.free_sku_qty ? (
              <Typography>
                <span style={{ fontWeight: "600" }}>Free Sku Qty:</span>{" "}
                {schemeDetails?.free_sku_qty}
              </Typography>
            ) : null} */}
          </Stack>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "0.8rem",
              alignItems: "center",
              // marginTop: "0.2rem",
              flexWrap: "wrap",
            }}
          >
            <Typography sx={{ fontSize: "20px" }}>Free SKU Options :</Typography>
            {schemeDetails?.free_sku_name?.map((sku, index) => (
              <Chip
                key={index} // Ideally, use a unique identifier for each SKU
                label={sku}
                // onDelete={() => handleDelete(sku)} // Function to handle deletion
                style={{ backgroundColor: "#f0f0f0", color: "#333" }}
              />
            ))}
          </Box>
          <Stack>
            <Typography
              sx={{
                textAlign: "left",
                fontSize: "20px",
                fontWeight: "700",
                marginBottom: "12px",
              }}
            >
              Business Impact{" "}
            </Typography>

            <Stack>
              <SalesChart />
            </Stack>
          </Stack>
        </Stack>
      ) : null}
      <div id="SchemeGraphDetails"></div>
    </Stack>
  );
}
